import React, { useEffect, useState } from 'react'
import useSwr from 'swr'
import { Error, Loading, fetcher } from '../../UI/CentersViewData'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import InstanceIndex from '../../../components/UI/AddingData/InstanceIndex'
import { t } from 'i18next'
import { HandleButton, TextInput } from '../../Users/CreateUserScreen'
import axios from 'axios'
import { useHistory } from 'react-router-dom/'
import useQuery from '../../../customFunction/useQuery'
import ArrayIndex from '../../../components/UI/AddingData/ArrayIndex'
import CommonInputsContainer from '../../../components/UI/TableView/CommonInputsContainer'

function CafeteriaStockDetails() {
    // productId
    const { productId } = useParams()
    const isNew = useQuery().get('new')
    const { data, isLoading, error } = useSwr('/cafeteria-stocks/' + productId + '?isNew=' + isNew, fetcher)
    const { data: brands, isLoading: brandIsLoading, error: brandError } = useSwr('/cafeteria-brands?all=true', fetcher)
    const [currentData, setCurrentData] = useState()
    const history = useHistory()

    useEffect(() => {
        setup()
    }, [data])

    const setup = () => {
        if (data && data[0]) {
            setCurrentData(data[0])
        }
    }

    if (isLoading) {
        return <Loading />
    }
    if (error) {
        return <Error />
    }

    const onChangeValue = (key, subKey, value) => {
        if (key === 'prices') {
            setCurrentData({
                ...currentData,
                [key]: {
                    ...currentData[key],
                    [subKey]: value,
                },
            });
        } else if (key === 'hasPartner') {

            currentData.hasPartner = !(currentData?.hasPartner || false);
        } else
            setCurrentData({ ...currentData, [key]: subKey })
    }

    const onSave = async () => {
        try {
            if (!currentData.name || currentData.quantity === undefined || currentData.prices.singlePrice === undefined || currentData.imageUri === undefined) {
                alert('Please fill all the fields')
                return
            }
            if (isNew)
                await axios.post('/cafeteria-stocks', currentData)
            else
                await axios.put('/cafeteria-stocks/' + productId, currentData)
            history.goBack()
        } catch (e) {
            alert(e.message)
        }

    }
    // console.log(currentData)
    return (
        <div className='flex flex-1 w-full' >
            <CommonInputsContainer onSave={onSave} title={t('Product')} >

                <InstanceIndex label={t("id")} value={`#${currentData && currentData._id ? currentData._id : ''}`} />
                <TextInput
                    label={t("productName")}
                    placeHolder={t("productName")}
                    value={currentData ? currentData.name : ''}
                    onChangeValue={onChangeValue.bind(this, 'name',)}
                    onlyReturnValue
                />
                <TextInput
                    label={t("quantity")}
                    placeHolder={t("quantity")}
                    value={currentData ? currentData.quantity : ''}
                    onChangeValue={onChangeValue.bind(this, 'quantity',)}
                    isNumber
                    onlyReturnValue

                />

                {/* prices */}
                <TextInput
                    label={t("buyPrice")}
                    placeHolder={t("buyPrice")}
                    value={currentData && currentData?.prices ? currentData?.prices?.buyPrice : ''}
                    onChangeValue={a => onChangeValue('prices', 'buyPrice', a)}
                    isNumber
                    onlyReturnValue
                />
                <TextInput
                    label={t("singlePrice")}
                    placeHolder={t("singlePrice")}
                    value={currentData && currentData.prices ? currentData.prices.singlePrice : ''}
                    onChangeValue={a => onChangeValue('prices', 'singlePrice', a)}
                    isNumber
                    onlyReturnValue
                />

                <TextInput
                    label={t("imageUri")}
                    placeHolder={t("imageUri")}
                    value={currentData ? currentData.imageUri : ''}
                    onlyReturnValue
                    onChangeValue={onChangeValue.bind(this, 'imageUri')}
                />
                {brands && (
                    <ArrayIndex
                        label={t("brands")}
                        data={brands}
                        valType={1}
                        onlyReturnValue
                        value={currentData?.brandId}
                        index='_id'
                        onChangeValue={onChangeValue.bind(this, 'brandId')}
                    />
                )}
            </CommonInputsContainer>

        </div>
    )
}

export default CafeteriaStockDetails
