import dayjs from "dayjs";
import expensesType from "../../data/expensesType";
import { t } from "i18next";
import { showInIQD } from "../../customFunction/Numeral/CurrencyFormat";

const ORDER_TYPE = [{ id: 0, label: 'cash' }, { id: 1, label: 'balance' }, { id: 2, label: 'garage' }, { id: 3, label: 'vipLocker' }, { id: 4, label: 'salon' }, { id: 6, label: 'cafeCard' }, { id: 7, label: 'physicalTherapy' },]

export const CENTERS_VIEW_DATA = [{
    'id': 'active-users',
    notByDate: true,
    href: '/users',
    columns: [{ label: '#', sort: 'bioStarID', value: a => a.bioStarID },
    { label: 'name', sort: 'name', value: a => a.name },
    { label: 'phoneNumber', sort: 'phone', classnames: () => 'rtl-grid justify-end', value: (a) => a.phone },
    {
        label: 'startDate',
        sort: 'start_datetime',
        value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm')
    },
    {
        label: 'expiryDate',
        sort: 'expiry_datetime',
        value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm')
    }],
    url: (centerId) => '/users?isExpire=false' + centerId, label: 'activeUsers', description: 'This Table Contain Last 10 Expired Users Of {{centerName}}',
},

{
    id: 'expire-users', href: '/users', label: 'expiredUser'
    , url: (centerId) => '/users?isExpire=true' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}',
    columns: [{ label: '#', sort: 'bioStarID', value: a => a.bioStarID }, { label: 'name', sort: 'name', value: a => a.name }, { label: 'phoneNumber', 'sort': 'phone', classnames: () => 'rtl-grid justify-end', value: (a) => a.phone }, { label: 'startDate', sort: 'start_datetime', value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm') }, { label: 'expiryDate', sort: 'end_datetime', value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD') },],
},
{
    id: 'male-users',
    notByDate: true,
    href: '/users',
    columns: [{ label: '#', value: a => a.bioStarID, sort: 'bioStarID' },
    { label: 'name', value: a => a.name, sort: 'name' },
    { label: 'phoneNumber', sort: 'phone', classnames: () => ' ', value: (a) => a.phone },
    {
        label: 'startDate',
        value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm'),
        sort: 'start_datetime'
    },
    {
        label: 'expiryDate',
        value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm'),
        sort: 'expiry_datetime'
    }],
    label: 'maleUsers', url: (centerId) => '/users?isExpire=false&isMale=true' + centerId, description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'female-users',
    notByDate: true,
    href: '/users',
    columns: [{ label: '#', value: a => a.bioStarID, sort: 'bioStarID' },
    { label: 'name', value: a => a.name, sort: 'name' },
    { label: 'phoneNumber', value: (a) => a.phone, sort: 'phone' },
    {
        label: 'startDate',
        value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm'),
        sort: 'start_datetime'
    },
    {
        label: 'expiryDate',
        value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm'),
        sort: 'expiry_datetime'
    },], label: 'femaleUsers', url: (centerId) => '/users?isExpire=false&isFemale=true' + centerId, description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'users',
    label: 'users',
    href: '/users',
    notByDate: true,
    columns: [{ label: '#', value: a => a.bioStarID, sort: 'bioStarID' },
    { label: 'name', value: a => a.name, sort: 'name' },
    { label: 'phoneNumber', value: (a) => a.phone, sort: 'phone' },
    { label: 'startDate', value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm'), sort: 'start_datetime' },
    { label: 'expiryDate', value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm'), sort: 'expiry_datetime' },],
    url: (centerId) => '/users?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'memberships',
    label: 'memberships',
    href: '/users',
    hrefId: 'originalDocument',
    hrefIdSubKey: 'userId',
    filter: [{ label: 'packages', query: 'packages', type: 0 }],//! 0 for select
    columns: [{ label: 'packageName', classnames: ({ originalDocument }) => ' font-[Montserrat] ' + (originalDocument.package.price.value === 0 ? 'text-[#009EDF]' : originalDocument.package.offerType === 1 ? 'text-[#9854cc]' : 'text-[#397d54]'), value: a => a.originalDocument.package.labelDetails.inEn.title, sort: 'originalDocument.package.labelDetails.inEn.title' },
    { label: 'name', value: a => a.userName, sort: 'userName' },
    { label: 'gender', sort: 'gender', classnames: ({ originalDocument }) => `  ${originalDocument.user.gender ? 'text-[#f57ca1]' : 'text-[#0293c2]'} `, value: a => a.gender === 0 ? t('male') : t('female') },
    { label: 'price', sort: 'originalDocument.package.price.value', classnames: () => 'font-[Montserrat]', value: ({ originalDocument }) => (originalDocument.package.price.value + '$ - ' + originalDocument.package.price.iqd + ' IQD ') },
    { label: 'receptionName', sort: 'originalDocument.admin.name', value: ({ originalDocument }) => originalDocument.admin[0]?.name },
    { label: 'date', sort: 'originalDocument.createdAt', value: (a) => dayjs(a.originalDocument.createdAt).format('YYYY-MM-DD HH:mm') },
    { label: 'expiryDate', sort: 'originalDocument.user.expiry_datetime', value: (a) => dayjs(a.originalDocument.user.expiry_datetime).format('YYYY-MM-DD HH:mm') },],
    url: (centerId) => '/memberships?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'new-subscription',
    label: 'newSubscribers',
    href: '/users',
    hrefId: 'originalDocument',
    hrefIdSubKey: 'userId',
    filter: [{ label: 'packages', query: 'packages', type: 0 }],//! 0 for select
    columns: [{ label: 'packageName', sort: 'originalDocument.package.labelDetails.inEn.title', classnames: ({ originalDocument }) => ' font-[Montserrat] ' + (originalDocument.package.price.value === 0 ? 'text-[#009EDF]' : originalDocument.package.offerType === 1 ? 'text-[#9854cc]' : 'text-[#397d54]'), value: a => a.originalDocument.package.labelDetails.inEn.title },
    { label: 'name', value: a => a.userName, sort: 'userName' },
    { label: 'gender', sort: 'gender', classnames: ({ originalDocument }) => ` ${originalDocument.user.gender === 1 ? 'text-[#f57ca1]' : 'text-[#0293c2]'} `, value: a => a.gender === 0 ? 'male' : 'female', },
    { label: 'price', sort: 'originalDocument.package.price.value', classnames: () => 'font-[Montserrat]', value: ({ originalDocument }) => (originalDocument.package.price.value + '$ - ' + originalDocument.package.price.iqd + ' IQD ') },
    { label: 'phoneNumber', value: (a) => a.phone, sort: 'phone' },
    { label: 'receptionName', sort: 'originalDocument.admin.name', value: ({ originalDocument }) => originalDocument.admin[0].name },
    { label: 'date', sort: 'originalDocument.createdAt', value: (a) => dayjs(a.originalDocument.createdAt).format('YYYY-MM-DD HH:mm') },
    { label: 'expiryDate', sort: 'originalDocument.expiry_datetime', value: (a) => dayjs(a.originalDocument.expiry_datetime).format('YYYY-MM-DD HH:mm'), },],
    url: (centerId) => '/memberships?newSubscription=true' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'renewal-subscription',
    label: 'renewalOfSubscriptions',
    href: '/users',
    hrefId: 'originalDocument',
    hrefIdSubKey: 'userId',
    filter: [{ label: 'packages', query: 'packages', type: 0 }],//! 0 for select
    columns: [{ label: 'packageName', sort: 'originalDocument.package.labelDetails.inEn.title', classnames: ({ originalDocument }) => ' font-[Montserrat] ' + (originalDocument.package.price.value === 0 ? 'text-[#009EDF]' : originalDocument.package.offerType === 1 ? 'text-[#9854cc]' : 'text-[#397d54]'), value: a => a.originalDocument.package.labelDetails.inEn.title },
    { label: 'name', value: a => a.userName, sort: 'userName' },
    { label: 'gender', sort: 'gender', classnames: ({ originalDocument }) => ` ${originalDocument.user.gender === 1 ? 'text-[#f57ca1]' : 'text-[#0293c2]'} `, value: a => a.gender === 0 ? 'male' : 'female', },
    { label: 'price', sort: 'originalDocument.package.price.value', classnames: () => 'font-[Montserrat]', value: ({ originalDocument }) => (originalDocument.package.price.value + '$ - ' + originalDocument.package.price.iqd + ' IQD ') },
    { label: 'phoneNumber', value: (a) => a.phone, sort: 'phone' },
    { label: 'receptionName', sort: 'originalDocument.admin.name', value: ({ originalDocument }) => originalDocument.admin[0].name },
    { label: 'date', sort: 'originalDocument.createdAt', value: (a) => dayjs(a.originalDocument.createdAt).format('YYYY-MM-DD HH:mm') },
    { label: 'expiryDate', sort: 'originalDocument.expiry_datetime', value: (a) => dayjs(a.originalDocument.expiry_datetime).format('YYYY-MM-DD HH:mm'), },], url: (centerId) => '/memberships?renewalSubscription=true' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'doors', label: 'openingTheDoors',
    columns: [
        { label: 'door', sort: 'door.name', value: (a) => a.door.name },
        { label: 'reason', sort: 'reasonLabel', classnames: (a) => 'text-red-500', value: a => a.reasonLabel },
        { label: 'by', sort: 'admin.name', value: a => a.admin.name },
        { label: 'date', sort: 'createdAt', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm') },
    ],
    url: (centerId) => '/doors?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}',

},
{
    id: 'free-subscription', label: 'freeSubscriptions',
    notByDate: true,
    href: '/users',
    columns: [{ label: '#', sort: 'bioStarID', value: a => a.bioStarID },
    { label: 'name', value: a => a.name, sort: 'name' },
    { label: 'phoneNumber', value: (a) => a.phone, sort: 'phone' },
    { label: 'startDate', value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm'), sort: 'start_datetime' },
    { label: 'expiryDate', value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm'), sort: 'expiry_datetime' },],
    url: (centerId) => '/users?freeSubscriptions=true' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'expiry-today-memberships', label: 'expiryTodayMemberships',
    href: '/users',
    columns: [{ label: '#', value: a => a.bioStarID, sort: 'bioStarID' },
    { label: 'name', value: a => a.name, sort: 'name' },
    { label: 'phoneNumber', classnames: () => 'rtl-grid justify-end', value: (a) => a.phone, sort: 'phone' },
    { label: 'startDate', value: (a) => dayjs(a.start_datetime).format('YYYY-MM-DD HH:mm'), sort: 'start_datetime' },
    { label: 'expiryDate', value: (a) => dayjs(a.expiry_datetime).format('YYYY-MM-DD HH:mm'), sort: 'expiry_datetime' },],
    url: (centerId) => '/users?todayExpiry=true' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'courses', label: 'requestCourse',
    href: '/courses',

    columns: [{ label: 'captainName', value: a => a.trainer[0].name, sort: 'trainer.name' },
    { label: 'playerName', value: a => a.userName, sort: 'userName' },
    { label: 'price', classnames: () => 'font-[Montserrat]', sort: 'price', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD') },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/courses?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'supplements', label: 'supplements',
    href: '/supplements',
    columns: [{ label: 'supplementName', value: a => a.name, sort: 'name' },
    { label: 'captainName', value: a => a.trainer && a.trainer[0] ? a.trainer[0].name : '78 Gym', sort: 'trainer.name' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/nutrition-sales?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'swimming-items', label: 'swimming-items',
    href: '/swimming-items',
    columns: [
        { label: '#', value: a => a._id.slice(10), },
        { label: 'name', value: a => a.name, sort: 'name' },
        { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
        { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/swimming-sales?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'cafeteria', label: 'cafeteria',
    href: '/cafeteria',
    columns: [{ label: 'label', value: a => a.name, sort: 'name' },
    { label: 'name', value: a => a.user?.name || ' - ' },
    { label: 'type', value: (a) => t(ORDER_TYPE.find(v => v.id === a.type).label), sort: 'type' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => a.iqd },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/orders?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'physical-therapy', label: 'physical-therapy',
    href: '/physical-therapy',
    columns: [{ label: 'label', value: a => t(a.name), sort: 'name' },
    { label: 'name', value: a => a.user?.name || ' - ' },
    { label: 'type', value: (a) => t(ORDER_TYPE.find(v => v.id === a.type).label), sort: 'type' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/orders?type=7&' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'expenses', label: 'expenses',
    href: '/expenses',
    columns: [{ label: 'name', value: a => a.name, sort: 'name' },
    { label: 'type', value: (a) => expensesType.find(b => b.type === a.type).label, sort: 'type' },
    { label: 'admin', value: (a) => a.admin && a.admin[0] ? a.admin[0].name : '', },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/expenses?' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'gym-expenses', label: 'gymExpenses',
    href: '/expenses',
    columns: [{ label: 'name', value: a => a.name, sort: 'name' },
    { label: 'type', value: (a) => expensesType.find(b => b.type === a.type).label, sort: 'type' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/expenses?type=1' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'cafeteria-expenses', label: 'cafeteriaExpenses',
    href: '/expenses',
    columns: [{ label: 'name', value: a => a.name, sort: 'name' },
    { label: 'type', value: (a) => expensesType.find(b => b.type === a.type).label, sort: 'type' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/expenses?type=2' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
{
    id: 'big-expenses', label: 'bigExpenses',
    href: '/expenses',
    columns: [{ label: 'name', value: a => a.name, sort: 'name' },
    { label: 'price', classnames: () => 'font-[Montserrat]', value: (a) => showInIQD(a.iqd, 'IQD') + ' - ' + showInIQD(a.price, 'USD'), sort: 'price' },
    { label: 'type', value: (a) => expensesType.find(b => b.type === a.type).label, sort: 'type' },
    { label: 'date', value: (a) => dayjs(a.createdAt).format('YYYY-MM-DD HH:mm'), sort: 'createdAt' }],
    url: (centerId) => '/expenses?type=3' + centerId,
    description: 'This Table Contain Last 10 Expired Users Of {{centerName}}'
},
]